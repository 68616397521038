/**
 * import
 *
 * @version 0.1 (2021.06.25 : usayama)
 */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IoKeyOutline } from 'react-icons/io5'
import { useForm } from 'react-hook-form'
// conf, action
import { APP_ROUTE } from '@config/routers'
import { VALIDATION_PATTERNS } from '@config/validation.patterns'
import authActions from '@redux/auth/actions'
import modalActions from '@redux/modal/actions'
// components
import ModalLoading from '@components/UIKit/ModalLoading'
import InputGroup from '@components/Form/InputGroup'
import Alert from '@components/UIKit/Alert'
import Button from '@components/UIKit/Button'
import LinkButton from '@components/UIKit/LinkButton'
import Label from '@components/Typo/Label'
// containers
// helper
// style, img, elm
// const
const { initChangePassword, changePassword } = authActions
const { initModal } = modalActions

/** 
 * export : 
 * 
 * @version 0.1 (2021.06.25 : usayama_dx)
*/
export default function ChangePasswordFormContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.25 : usayama)
   */
  const { handleSubmit, 
          register, 
          formState, 
          setError,
          watch,
          errors } = useForm({ mode: 'onChange' })
  const { verify_url } = props
  const dispatch = useDispatch()
  const { loadChangePassword, changePasswordResultStatus } = useSelector(state => state.Auth)

  /** 
   * useState : 
   * 
   * @version 0.1 (2021.06.25 : usayama_dx)
   */
  const [ initPage, setInitPage ] = React.useState(false)

  /** 
   * event : 
   * 
   * @version 0.1 (2021.06.25 : usayama)
   */
  const handleOnSubmit = (d) => {
    dispatch(changePassword({'verify_url' : verify_url, ...d}))
  }

  /** 
   * useEffect
   * 
   * @version 0.1 (2021.06.25 : usayama)
   */
  React.useEffect(() => {
    if(changePassword && changePassword.errors) {
      Object.keys(changePassword.errors).map((key) => setError(key, { type: "manual", message: changePassword.errors[key] }))
    }
  }, [setError])

  /** 
   * componentDidMount : https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.06.25 : usayama)
   */
  React.useEffect(() => {
    if(!initPage) {
      dispatch(initChangePassword())
      dispatch(initModal())
      setInitPage(true)
    }
  }, [initPage, dispatch])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.06.25 : usayama_dx)
   * @version 0.2 (2021.06.30 : usayama_dx) // link先をログインページに変更
   */
  return (
    <div>
      <ModalLoading isLoading={loadChangePassword} />
      <p className="mb-3"><Label label="text.change_password" /></p>
      {
        changePasswordResultStatus && 
        <Alert 
          status={changePasswordResultStatus} 
          label={"message." + changePasswordResultStatus + ".change_password"} 
          isOpen={true} 
          closeBt={true}
        />
      }
      {
        changePasswordResultStatus === 'success'
        ? <div className="text-center mt-3 mb-3"><LinkButton to={APP_ROUTE.login} label="text.to_login" /></div>
        : <form onSubmit={handleSubmit(handleOnSubmit)}>
            <InputGroup
              label={<IoKeyOutline size={25} />}
              placeholder="placeholder.change_password_password"
              type="password"
              name="password"
              feedback={errors && errors.password}
              errors={errors && errors.password}
              className={errors.password && "is-invalid"}
              inputClassName={errors.password && "is-invalid"}
              maxLength={255}
              inputRef={register({ required: true, pattern: VALIDATION_PATTERNS.password })}
             />
            <InputGroup
              label={<IoKeyOutline size={25} />}
              placeholder="placeholder.change_password_password_confirm"
              type="password"
              name="password_confirm"
              feedback={errors && errors.password_confirm}
              errors={errors && errors.password_confirm}
              className={errors.password_confirm && "is-invalid"}
              inputClassName={errors.password_confirm && "is-invalid"}
              maxLength={255}
              inputRef={register({ 
                required: true, 
                pattern: VALIDATION_PATTERNS.password,
                validate: value => value === watch('password')
              })}
             />
            <div className="form-action">
              <Button type="submit" color="success" label="change_password" disabled={!formState.isValid} />
            </div>
          </form>
      }
    </div>
  )
}
