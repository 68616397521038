/**
 * import
 *
 * @version 0.1 (2021.06.25 : usayama)
 * @version 0.2 (2021.06.28 : usayama) // Seo 追加
 */
import React from 'react'
import { Router } from '@reach/router'
import { useTranslation } from 'react-i18next'
// conf, action
// components
import Seo from '@components/Tools/Seo'
// containers
import LayoutContainer from '@containers/LayoutContainer'
import AuthContainer from '@containers/AuthContainer'
import ChangePasswordContainer from '@containers/ChangePasswordContainer'
// helper
// style, img, elm
// const

/**
 * const
 *
 * @version 0.1 (2021.06.25 : usayama)
 */
const ChangePassword = (props) => {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.28 : usayama_dx)
   */
  const { t } = useTranslation()

	/**
	 * return
	 *
	 * @version 0.1 (2021.06.25 : usayama)
	 * @version 0.2 (2021.06.28 : usayama) // Seo 追加
	 */
	return (
	  <LayoutContainer>
	  	<Seo title={t('title.change_password')} />
	  	<AuthContainer titleLabel="title.change_password">
		    <Router basepath='/change_password'>
		      <ChangePasswordContainer path='/:verify_url' />
		      <ChangePasswordContainer path='/' />
		    </Router>
	  	</AuthContainer>
	  </LayoutContainer>
	)
}

/**
 * export
 *
 * @version 0.1 (2021.06.25 : usayama)
 */
export default ChangePassword
