/**
 * import
 *
 * @version 0.1 (2021.06.25 : usayama)
 */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
// conf, action
import { APP_ROUTE } from '@config/routers'
import authActions from '@redux/auth/actions'
// components
import Alert from '@components/UIKit/Alert'
import LinkButton from '@components/UIKit/LinkButton'
// containers
import ChangePasswordFormContainer from '@containers/ChangePasswordFormContainer'
// helper
import { isEmpty } from '@lib/helpers'
// style, img, elm
// const
const { initVerifyChangePasswordUrl, verifyChangePasswordUrl } = authActions

/** 
 * export : 
 * 
 * @version 0.1 (2021.06.25 : usayama_dx)
*/
export default function ChangePasswordContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.25 : usayama)
   */
  const { verify_url } = props
  const dispatch = useDispatch()
  const { verifyChangePasswordUrlResultStatus } = useSelector(state => state.Auth)

  /** 
   * useState : 
   * 
   * @version 0.1 (2021.06.25 : usayama_dx)
   */
  const [ initPage, setInitPage ] = React.useState(false)

  /** 
   * componentDidMount : https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.06.25 : usayama)
   */
  React.useEffect(() => {
    if(!initPage) {
      dispatch(initVerifyChangePasswordUrl())
      if(verify_url) {
        dispatch(verifyChangePasswordUrl({ 'verify_url': verify_url }))
      }
      setInitPage(true)
    }
  }, [initPage, dispatch, verify_url])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.06.25 : usayama_dx)
   */
  return (
    <div>
      { 
        (isEmpty(verify_url) || verifyChangePasswordUrlResultStatus === 'error' ) && 
          <div>
            <Alert status="error" label={"message.error.verify_url"} isOpen={true} closeBt={false} />
            <LinkButton className="mb-1 text-center" to={APP_ROUTE.login} label="text.to_login" /> 
          </div>
      }
      { 
        (!isEmpty(verify_url) && verifyChangePasswordUrlResultStatus === 'success' ) && 
          <ChangePasswordFormContainer verify_url={verify_url} />
      }
    </div>
  )
}
